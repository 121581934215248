import MainLayout from '../../layouts/MainLayout'

export default function Suppliers(): JSX.Element {
  return (
    <MainLayout>
      <div className="max-w-screen-3xl mx-auto w-full">
        <div className="flex-grow lg:p-10 p-3 lg:space-y-8 space-y-3">

          <div className="flex flex-col items-center w-full bg-white rounded-xl p-4 lg:p-5">
            <div className="flex w-full mb-4">
              <header className="mb-4">
                <h1 className="sm:text-2xl text-xl font-bold">Recommended Suppliers</h1>
              </header>
            </div>

            <div className="flex flex-col w-full text-sm">
              { /*Supplier Start - Eden */ }
              <div className="flex w-full flex-col justify-center p-4">
                <h2 className="text-2xl font-medium mb-2">Eden</h2>

                <p className="mb-4">
                  England’s leading Early Years training specialists – <strong>Eden Training Solutions</strong>
                </p>

                <p className="mb-4">
                  Delivering bespoke apprenticeships across the childcare sector, Eden are our specialist partner in early years training. They continue to set the standard for excellence in learning, empowering apprentices across the Banana Moon Franchise to develop the knowledge and skills needed to positively impact on their setting and the children they care for.
                </p>

                <p className="mb-4">
                  Eden have the highest qualification achievement rates (QAR) in the early years sector for the 2022/23 academic year (Tier 2: Child Development & Well-Being) for specialist early years apprenticeship training providers in England. As an early years employer, your main priority is the children you care for. You need a confident and qualified team of experts you can trust. As a partner with Eden, you’ll gain access to hundreds of early years experts to support you and your apprentice through their programme of learning.
                </p>

                <p>
                  Think early years, think Eden.
                </p>

                <div className="flex w-full flex-col md:flex-row">
                  <div className="flex flex-col font-bold w-full md:w-1/2">
                    Contact:<br />
                    <a className="text-blue-secondary hover:underline" href="mailto:enquiries@eden-ts.com">enquiries@eden-ts.com</a>
                    <a className="text-blue-secondary hover:underline" target="_blank" href="https://www.eden-ts.com/">www.eden-ts.com</a>
                    <a className="text-blue-secondary hover:underline" href="tel:08007563985">0800 756 3985</a>
                  </div>

                  <div className="flex justify-center w-full md:w-1/2">
                    <a className="flex justify-center items-center mt-4 md:mt-0" href="https://www.eden-ts.com/" target="_blank">
                      <img src="/images/suppliers/Eden-Logo-Gold-Black.png" style={ { maxHeight: '80px' } } alt="Eden" />
                    </a>
                  </div>
                </div>
              </div>
              <hr />
              { /*Supplier End - eden */ }

              { /*Supplier Start - Moore & Tibbits */ }
              <div className="flex w-full flex-col justify-center p-4">
                <h2 className="text-2xl font-medium mb-2">Moore & Tibbits</h2>

                <p className="mb-4">
                  Located in the heart of Warwick, Moore & Tibbits Solicitors have been a trusted pillar of the community for over 190 years, serving individuals and businesses alike. Their culture is aligned with Banana Moon in that they put clients first, and they recognise the importance of clear and concise legal advice.
                </p>

                <p className="mb-4">
                  Treat Moore & Tibbits as an extension of your business. They’re able to support you with your legal needs, whether that be leasing your first nursery, dealing with the sale or purchase of another business, advising on employment matters and more.
                </p>

                <p className="mb-4">
                  The dedicated team have a wealth of experience and are with you every step of the journey, ensuring that you’re always able to contact someone who can progress your matter in an engaging and supporting manner.
                </p>

                <p className="mb-4">
                  They offer fixed prices for their services, ensuring transparency and peace of mind throughout the process. Moore & Tibbits will help you “find a place to grow their world!”
                </p>

                <div className="flex w-full flex-col md:flex-row">
                  <div className="flex flex-col font-bold w-full md:w-1/2">
                    Contact:<br />
                    <a className="text-blue-secondary hover:underline" href="mailto:email@moore-tibbits.co.uk">email@moore-tibbits.co.uk</a>
                    <a className="text-blue-secondary hover:underline" target="_blank" href="https://www.moore-tibbits.co.uk/">www.moore-tibbits.co.uk</a>
                    <a className="text-blue-secondary hover:underline" href="tel:01926491181">01926 491181 (High Street)</a>
                    <a className="text-blue-secondary hover:underline" href="tel:01926354704">01926 354704 (Westgate House)</a>
                  </div>

                  <div className="flex justify-center w-full md:w-1/2">
                    <a className="flex justify-center items-center mt-4 md:mt-0" href="https://www.moore-tibbits.co.uk/" target="_blank">
                      <img src="/images/suppliers/moore-tibbits-logo.jpg" style={ { maxHeight: '150px' } } alt="Eden" />
                    </a>
                  </div>
                </div>
              </div>
              <hr />
              { /*Supplier End - Moore & Tibbits */ }

              { /*Supplier Start - FireNet */ }
              <div className="flex w-full flex-col justify-center p-4">
                <h2 className="text-2xl font-medium mb-2">FireNet</h2>

                <p className="mb-4">FireNet Security are an independent company.</p>

                <div className="flex flex-col">
                  <p className="mb-4">They operate within standards and have a clear focus on their priorities:</p>

                  <div className="flex ml-4 mb-4">
                    <ul className="w-full list-disc pl-4 mb-4">
                      <li>Partnership with our customers based on repeat business</li>
                      <li>Professional and reliable engineering</li>
                      <li>Quality products and value for money</li>
                    </ul>
                  </div>
                </div>

                <p className="mb-4">
                  Engineered solutions are a strength for FireNet Security. They use the best equipment available and add extensive engineering knowledge and quality aftercare. Their equipment suppliers are world leaders in fire safety products and staff are comprehensively trained, experienced and committed to delivering the best service time after time all year round, through the 24/7, 365-day-a-year emergency call-out service.
                </p>

                <div className="flex w-full flex-col md:flex-row">
                  <div className="flex flex-col font-bold w-full md:w-1/2">
                    Contact:<br />
                    Toby Mynett<br />
                    <a className="text-blue-secondary hover:underline" href="mailto:info@firenetsecurity.co.uk">info@firenetsecurity.co.uk</a>
                    <a className="text-blue-secondary hover:underline" href="tel:07932032466">07932032466</a>
                  </div>

                  <div className="flex justify-center w-full md:w-1/2">
                    <a className="flex justify-center items-center mt-4 md:mt-0" href="https://www.firenetsecurity.co.uk/" target="_blank">
                      <img src="/images/suppliers/firenet.png" style={ { maxHeight: '120px' } } alt="FireNet" />
                    </a>
                  </div>
                </div>
              </div>
              <hr />
              { /*Supplier End - FireNet */ }

              { /*Supplier Start - One MSP */ }
              <div className="flex w-full flex-col justify-center p-4">
                <h2 className="text-2xl font-medium mb-2">One MSP</h2>

                <p className="mb-4">
                  OneMSP handles all IT across Banana Moon’s 50 locations. They understand what matters: reliable systems that just work, keeping customers safe, productive and able to deliver their best products and services.
                </p>

                <div className="flex flex-col">
                  <p className="mb-4">Core services include:</p>

                  <div className="flex ml-4 mb-4">
                    <ul className="w-full list-disc pl-4 mb-4">
                      <li><strong>Security</strong> – High-definition CCTV and access control systems to protect customers and their premises around the clock.</li>
                      <li><strong>Network</strong> – Fast, reliable Wi-Fi and network infrastructure, always ensuring connectivity.</li>
                      <li><strong>Hardware</strong> – Quality computers and equipment, fully supported and maintained for optimal performance.</li>
                      <li><strong>Digital security</strong> – Advanced data protection and cybersecurity measures to safeguard employees and sensitive information.</li>
                      <li><strong>Productivity software</strong> – Tailored business software utilising AI and the latest Microsoft applications to streamline daily operations.</li>
                    </ul>
                  </div>
                </div>

                <p className="mb-4">
                  By handling all technology, from maintenance to upgrades, OneMSP create reliable foundations for their customers’ operations. The company’s proactivity ensures potential issues are addressed before they impact business activities, providing customers with evolving technology that remains best-in-class and support just a call away.
                </p>

                <div className="flex w-full flex-col md:flex-row">
                  <div className="flex flex-col font-bold w-full md:w-1/2">
                    Contact:<br />
                    <a className="text-blue-secondary hover:underline" href="mailto:support@onemsp.com">support@onemsp.com</a>
                    <a className="text-blue-secondary hover:underline" href="https://onemsp.com" target="_blank">www.onemsp.com</a>
                    <a className="text-blue-secondary hover:underline" href="tel:+443335771555">0333 577 1555</a>
                  </div>

                  <div className="flex justify-center w-full md:w-1/2">
                    <a className="flex justify-center items-center mt-4 md:mt-0" href="https://onemsp.com" target="_blank">
                      <img src="/images/suppliers/onemsp.jpg" style={ { maxHeight: '100px' } } alt="One MSP" />
                    </a>
                  </div>
                </div>

              </div>
              <hr />
              { /*Supplier End - One MSP */ }

              { /*Supplier Start - Hope Education */ }
              <div className="flex w-full flex-col justify-center p-4">
                <h2 className="text-2xl font-medium mb-2">Hope Education</h2>

                <p className="mb-4">
                  Hope believes the people who nurture young minds are some of the most important people on Earth. You pass on knowledge, build confidence, fuel imagination and enrich the future.
                </p>

                <p className="mb-4">
                  To make your life easier, Hope curates and makes the most inventive and extensive range of products, covering everything an early years setting needs, from role play, construction and small world to furniture, cleaning and art. Plus, endless inspiration to help you bring learning to life.
                </p>

                <p className="mb-4">
                  For nurturing our youngest generation, Hope is all you need.
                </p>

                <p className="mb-4">
                  5 reasons to choose Hope….
                </p>

                <p className="mb-4">
                  <strong>1. Over 30,000 quality and inspirational resources🌟</strong><br/>
                  Hope sells everything you need to help run your nursery.
                </p>

                <p className="mb-4">
                  <strong>2. Rated Excellent on Trustpilot⭐</strong><br/>
                  Join thousands of customers who have given Hope top marks for their quality products and exceptional service.
                </p>

                <p className="mb-4">
                  <strong>3. FREE next day delivery🚚</strong><br/>
                  Speedy, eco-friendly delivery service means you receive what you need when you need it.
                </p>

                <p className="mb-4">
                  <strong>4. FREE smart ordering tools⏳</strong><br/>
                  Designed to streamline your workflow and save you valuable time.
                </p>

                <p className="mb-4">
                  <strong>5. They’re doing good things with their ESG commitments🌿</strong><br/>
                  Hope is passionate about placing sustainability and well-being at the heart of everything they do.
                </p>

                <div className="flex w-full flex-col md:flex-row">
                  <div className="flex flex-col font-bold w-full md:w-1/2">
                    Contact:<br />
                    Account Manager: Judy Whitehead<br />
                    <div>
                      Email: <a className="text-blue-secondary hover:underline" href="mailto:judy.whitehead@findel.co.uk">judy.whitehead@findel.co.uk</a>
                    </div>

                    <div>
                      Phone: <a className="text-blue-secondary hover:underline" href="tel:+447753776555">0775 377 6555</a>
                    </div>

                    <br />

                    <div className="break-words">
                      Website link:
                      <a className="text-blue-secondary hover:underline" target="_blank" href="https://www.hope-education.co.uk/early-years-resources-inspiration">https://www.hope-education.co.uk/early-years-resources-inspiration</a>
                    </div>

                    <br />

                    <div className="break-words">
                      Latest digital catalogue:<br />
                      <a className="text-blue-secondary hover:underline" target="_blank" href="https://content.yudu.com/web/43fkl/0A43zj5/NursFavourites2023/html/index.html">https://content.yudu.com/web/43fkl/0A43zj5/NursFavourites2023/html/index.html</a>
                    </div>
                  </div>

                  <div className="flex justify-center w-full md:w-1/2">
                    <a className="flex justify-center items-center mt-4 md:mt-0" href="https://www.hope-education.co.uk/" target="_blank">
                      <img src="/images/suppliers/big-Hope-Logo.jpg" style={ {
                        maxHeight: '100px'
                      } } alt="Hope Education" />
                    </a>
                  </div>
                </div>
              </div>
              <hr />
              { /*Supplier End - Hope Education */ }

              { /*Supplier Start - The Nursery & School Renovation Company */ }
              <div className="flex w-full flex-col justify-center p-4">
                <h2 className="text-2xl font-medium mb-2">The Nursery & School Renovation Company</h2>

                <p className="mb-4">
                  The Nursery & School Renovation Company are a professional, reliable, design and build company specialising in internal and external renovation and refurbishment projects for the Banana Moon Franchise Network.
                </p>

                <div className="flex flex-col">
                  <div className="flex ml-4 mb-4">
                    <ul className="w-full list-disc pl-4 mb-4">
                      <li>Design and build</li>
                      <li>Decoration and flooring</li>
                      <li>Builders works</li>
                      <li>Carpentry, electrical and plumbing works</li>
                      <li>Gardens, landscaping and play areas</li>
                    </ul>
                  </div>
                </div>

                <p className="mb-4">
                  They are a family-owned company with over 15 years’ experience in design and build projects. Their experience allows them to manage the project process from start to finish – from consultation and design to construction and handover, your project is handled by them. The Nursery & School Renovation Company guarantee a personal, professional and friendly service with the best aftercare. The majority of their business comes from recommendations or repeat customers, which is a testament to the excellent relationships they have with their clients.
                </p>

                <p className="mb-4">
                  Meet them at the NMT Childcare Show (Excel London) or The Childcare Expo (Olympia London) this year, where they will be exhibiting. Or contact them for a no-obligation survey and discussion in regards to your building or play area requirements.
                </p>

                <div className="flex w-full flex-col md:flex-row">
                  <div className="flex flex-col font-bold w-full md:w-1/2">
                    Contact:<br />
                    <a className="text-blue-secondary hover:underline" href="mailto:sales@theschoolrenovationcompany.co.uk">sales@theschoolrenovationcompany.co.uk</a>
                    <a className="text-blue-secondary hover:underline" target="_blank" href="https://www.theschoolrenovationcompany.co.uk/">www.theschoolrenovationcompany.co.uk</a>
                    <a className="text-blue-secondary hover:underline" href="tel:01296655037">01296 655037</a>
                  </div>

                  <div className="flex justify-center w-full md:w-1/2">
                    <a className="flex justify-center items-center mt-4 md:mt-0" href="https://www.theschoolrenovationcompany.co.uk/" target="_blank">
                      <img src="/images/suppliers/TNSRC-Logo.jpeg" style={ { maxHeight: '150px' } } alt="Eden" />
                    </a>
                  </div>
                </div>
              </div>
              <hr />
              { /*Supplier End - The Nursery & School Renovation Company */ }
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
